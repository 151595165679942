.mainContainer {
  color: #000;
  margin-top: 56px;

  & h3,
  & h4 {
    width: 100%;
    text-align: center;
  }

  & h3 {
    font-size: 36px;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    line-height: 45px;
  }

  & h4 {
    font-weight: 700;
    margin: 10px 0px 0px;
    font-family: 16px;
    color: #cecece;
  }

  & .pricing {
    color: #c93b55;
  }
}
.plansCard::after {
  content: 'Pause Anytime';
  background-color: #cecece;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
}
.plansCardContainer {
  display: flex;
  flex-direction: row;
  padding: 24px 0px 24px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;

  & .plansCardContent {
    display: flex;
    flex-direction: row;
    gap: 30px;
    @media (max-width: 575px) {
      flex-direction: column;
    }
  }

  & .plansCard {
    width: 225px;
    background-color: #fff;
    padding: 20px 16px 40px;
    margin: 0px 12px;
    box-shadow: 2px 2px 8px #0000005c;
    /* border-top: 5px solid transparent; */
    flex-shrink: 0;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: 575px) {
      width: 100%;
      max-width: 280px;
      margin: auto;
    }
    & h4 {
      font-weight: 700;
      font-size: 23px;
      color: #35b36c;
    }

    & .planType {
      display: block;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 8px;
      font-size: 17px;
      font-weight: 700;
    }

    & .monthlyPlanType {
      margin-top: -28px;
      display: block;
      font-size: 14px;
      text-align: center;
      margin-bottom: 8px;
    }

    & .planContent {
      display: block;
      font-size: 14px;
      line-height: 1.5rem;
      margin-bottom: 28px;
      font-weight: 600;
      text-align: center;
    }

    & .getStartBtn {
      cursor: pointer;
      text-decoration: none;
      display: block;
      padding: 0 10px;
      height: 36px;
      margin: 0 auto;
      color: #fff;
      border-radius: 8px;
      border: 0px;
      /* margin-top: auto; */

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.newbiePlansCard {
  border-top-color: #35b36c !important;
  &:after {
    content: 'Only pay commissions on completed bookings';
  }
  & .getStartBtn {
    background-color: #35b36c;
  }
}

.growthPlansCard {
  border-top-color: #2dadb9 !important;
  position: relative;
  overflow: hidden;
  &:after {
    content: 'Pause anytime';
  }
  &::before {
    content: 'Most Popular';
    width: 96px;
    height: 64px;
    background-color: #2dadb9;
    position: absolute;
    top: -20px;
    right: -36px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 28px 16px 0px;
    transform: rotate(45deg);
  }
  & h4 {
    color: #2dadb9 !important;
  }

  & .getStartBtn {
    background-color: #2dadb9;
  }
}

.proPlansCard {
  border-top-color: #c93b55 !important;
  position: relative;
  overflow: hidden;
  &:after {
    content: 'Best value, save €30';
  }
  & h4 {
    color: #c93b55 !important;
  }

  & .getStartBtn {
    background-color: #c93b55;
  }

  &::before {
    content: 'Best Value';
    width: 96px;
    height: 64px;
    background-color: #c93b55;
    position: absolute;
    top: -20px;
    right: -36px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 28px 16px 0px;
    transform: rotate(45deg);
    word-break: break-all;
    word-spacing: 40px;
  }
}
.enterprisePlansCard {
  border-top-color: #f8933b !important;
  &:after {
    content: 'Customised features';
  }
  & .planType {
    /* line-height: 1.25rem;
    margin-top: 12px !important; */
  }

  & h4 {
    color: #f8933b !important;
  }

  & .getStartBtn {
    background-color: #f8933b;
  }
}
