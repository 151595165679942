.mainContainer {
  color: #000;

  & h3 {
    width: 100%;
    text-align: center;
  }

  & h3 {
    font-size: 36px;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    line-height: 45px;
  }

  & h4 {
    font-weight: 700;
    margin: 10px 0px 0px;
    font-size: 16px;
  }

  & .pricing {
    color: #c93b55;
  }
}
.heading {
  font-weight: 400 !important;
  max-width: 520px;
  margin: 0 auto;
}
.subHeading {
  display: block;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  margin: 14px 0px;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 856px;
  /* width: 100%; */
  margin: 0 auto;

  & td {
    width: 276px;
    padding: 18px 16px;
    border: 1px solid #aaa;
    position: relative;

    & h4 {
      font-size: 18px !important;
      /* margin-bottom: 12px !important; */
      margin-top: 0px !important;
    }

    & span {
      display: block;
      font-size: 12px;
      line-height: 1.375rem;
      font-weight: 400;
      /* margin-top: -10px; */
    }

    & img {
      width: 36px;
      height: 36px;
    }
  }

  & td:first-child {
    padding-right: 36px;

    & .infoIcon {
      position: absolute;
      top: 13px;
      right: 9px;
      width: 24px;
      height: 24px;
      background-color: #ccc;
      border-radius: 50%;
      font-size: 18px;
      font-weight: 500;
      font-family: 'Stardos Stencil', cursive;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 2px;
      transform: scale(0.9);
      cursor: pointer;
      z-index: 0;

      &:hover + .infoContent,
      &:focus + .infoContent {
        opacity: 1;
      }
    }

    & .infoContent {
      position: absolute;
      width: 90%;
      min-height: 36px;
      background-color: #f2f1ef;
      top: 46px;
      left: 50%;
      transform: translateX(-50%);
      padding: 10px 8px;
      box-shadow: 0px 2px 5px #0009;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.25s linear;
      border-radius: 4px;
    }

    /* &::after{
          content:"i";
          
      } */
  }

  & td:not(:first-child) {
    width: 64px;
    text-align: center;
    /* background-color: red; */
  }

  & tbody {
    background-color: #fff;
    & tr:last-child td {
      border-color: #f2f1ef;
      background-color: #f2f1ef;

      &::after {
        display: none;
      }
    }
  }

  & thead {
    & th {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      padding: 8px 2px;
      position: relative;
    }

    & th:first-child {
      color: #534291;
      max-width: 276px;
    }
    & th:not(:first-child) {
      max-width: 64px;
    }

    & th:not(:first-child)::after {
      /* content: ''; */
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: transparent;
    }
    & th:nth-child(2) {
      color: #35b36c;

      &::after {
        background-color: #35b36c;
      }
    }
    & th:nth-child(3) {
      color: #2dadb9;

      &::after {
        background-color: #2dadb9;
      }
    }

    & th:nth-child(4) {
      color: #c93b55;
      &::after {
        background-color: #c93b55;
      }
    }
  }

  & .getStartedBtn {
    background-color: transparent;
    border-radius: 8px;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 108px;
    color: #fff !important;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  & .newbieBtn {
    background-color: #35b36c;
  }
  & .growthBtn {
    background-color: #2dadb9;
  }
  & .proBtn {
    background-color: #c93b55;
  }
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 0px 1rem;
}

.heading1 {
  color: #c93b54 !important;
}

.decoy1 {
  background: linear-gradient(90deg, rgb(201, 59, 84) 27%, rgb(146, 27, 152) 72%);
  border: 4px solid transparent;
  width: 100%;
  margin-bottom: 20px;
  & > div {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #002435;
    color: #fff;
    padding: 20px;
  }
}
.decoy {
  background: linear-gradient(90deg, rgb(201, 59, 84) 27%, rgb(146, 27, 152) 72%);
  border: 4px solid transparent;
  width: 100%;
  margin-bottom: 20px;
  & > div {
    background-color: #fff;
    padding: 25px 70px 70px;
    @media (max-width: 767px) {
      padding: 25px 10px 70px;
    }
  }
}
.urdv {
  padding: 15px 30px;
  border: 2px solid black;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    padding: 15px 30px;
  }
  & p {
    font-size: 32px;
    line-height: 50px;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    margin: 0;
    color: #000;
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 45px;
    }
  }
}
.txfee {
  padding: 20px 0 20px 30px;
  @media (max-width: 767px) {
    padding: 20px 0 20px 0px;
  }
  & p {
    margin: 10px 0;
    display: flex;
    align-items: center;
    & span {
      color: #717171;
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-size: 18px;
    }
  }
}
.tgldv {
  display: flex;
  padding: 15px 0 30px;
  align-items: center;
  justify-content: center;

  & > span {
    font-size: 18px;
    font-weight: 700;
  }
  & .tglrt {
    justify-content: flex-end;
  }
  & button {
    width: 40px;
    height: 22px;
    margin: 0 20px;
    border: 0;
    padding: 0 3px;
    border-radius: 10px;
    background-color: #35b36c;
    display: flex;
    align-items: center;
    & span {
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
}
.ndvs,
.nwhd {
  max-width: 856px;
  margin: auto;
}
.nwhd {
  padding: 35px 0 30px 24%;
  position: relative;
  & img {
    position: absolute;
    left: 39px;
    top: 0;
    max-height: 225px;
  }
  @media (max-width: 767px) {
    padding: 35px 0 30px;

    & img {
      position: relative;
      margin: auto;
      left: unset;
    }
  }
}
.getStartedBtnth {
  background-color: transparent;
  border-radius: 8px;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #fff !important;
  border: 0;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}
